"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApplyData = void 0;
const text_1 = require("../utils/text");
function getApplyData(data) {
    if (!data)
        return null;
    const { projectName, recrStartDate, recrEndDate, supportPeriod, supportAmount, portfolioFiles, supportContent } = data;
    return {
        projectName: (0, text_1.dashString)(projectName),
        recrStartDate: recrStartDate ? recrStartDate.substring(0, 10) : '',
        recrEndDate: recrEndDate ? recrEndDate.substring(0, 10) : '',
        supportPeriod: (0, text_1.dashString)(supportPeriod),
        supportAmount: (0, text_1.dashString)(supportAmount),
        portfolioFiles: portfolioFiles || [],
        supportContent: (0, text_1.dashString)(supportContent),
    };
}
exports.getApplyData = getApplyData;
