"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const style_1 = require("./style");
function RowNavigation({ navigations = [], styleTheme = 'inline', currentNavigation = '', }) {
    const navRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        if (navRef.current) {
            navRef.current.scrollIntoView({ block: 'center', inline: 'center' });
        }
    }, [currentNavigation, navRef]);
    return ((0, jsx_runtime_1.jsx)(style_1.RowNavigation, { styleTheme: styleTheme, children: (0, jsx_runtime_1.jsx)("ul", { children: navigations.map((nav, idx) => ((0, jsx_runtime_1.jsx)("li", { ...(currentNavigation === nav.path && { ref: navRef }), children: nav.path && ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: nav.path, className: currentNavigation === nav.path ? 'active' : '', children: nav.name })) }, idx))) }) }));
}
exports.default = RowNavigation;
