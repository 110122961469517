"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("./style");
const TimePicker = ({ hour = '0', minute = '0', styleTheme = 'inline', size = 'full', handleChange, }) => {
    const temphour = new Array(24).fill(0);
    const tempMinute = new Array(61).fill(0);
    const [activeTab, setAtiveTab] = (0, react_1.useState)(-1);
    const handleHour = (value) => {
        setAtiveTab(-1);
        handleChange(value, minute);
    };
    const handleMinute = (value) => {
        setAtiveTab(-1);
        handleChange(hour, value);
    };
    return ((0, jsx_runtime_1.jsxs)(style_1.TimePicker, { children: [(0, jsx_runtime_1.jsxs)(style_1.Time, { styleTheme: styleTheme, size: size, children: [(0, jsx_runtime_1.jsxs)(style_1.TimeView, { active: activeTab === 0, onClick: () => {
                            setAtiveTab((activeTab) => (activeTab === 0 ? -1 : 0));
                        }, children: [hour, " \uC2DC"] }), activeTab === 0 && ((0, jsx_runtime_1.jsx)(style_1.TimeOptions, { children: (0, jsx_runtime_1.jsx)("div", { children: temphour.map((item, idx) => {
                                const value = idx < 10 ? '0' + idx : '' + idx;
                                return ((0, jsx_runtime_1.jsxs)(style_1.TimeOption, { children: [(0, jsx_runtime_1.jsx)("input", { type: "radio", value: value, id: `hour_${idx}`, onChange: () => {
                                                handleHour(value);
                                            }, checked: value === hour }), (0, jsx_runtime_1.jsxs)("label", { htmlFor: `hour_${idx}`, children: [value, " \uC2DC"] })] }, idx));
                            }) }) }))] }), (0, jsx_runtime_1.jsxs)(style_1.Time, { styleTheme: styleTheme, size: size, children: [(0, jsx_runtime_1.jsxs)(style_1.TimeView, { active: activeTab === 1, onClick: () => {
                            setAtiveTab((activeTab) => (activeTab === 1 ? -1 : 1));
                        }, children: [minute, " \uBD84"] }), activeTab === 1 && ((0, jsx_runtime_1.jsx)(style_1.TimeOptions, { children: (0, jsx_runtime_1.jsx)("div", { children: tempMinute.map((item, idx) => {
                                const value = idx < 10 ? '0' + idx : '' + idx;
                                return ((0, jsx_runtime_1.jsxs)(style_1.TimeOption, { children: [(0, jsx_runtime_1.jsx)("input", { type: "radio", value: value, id: `minute_${idx}`, onChange: () => {
                                                handleMinute(value);
                                            }, checked: value === minute }), (0, jsx_runtime_1.jsxs)("label", { htmlFor: `minute_${idx}`, children: [value, " \uBD84"] })] }, idx));
                            }) }) }))] })] }));
};
exports.default = TimePicker;
