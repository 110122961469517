"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsultCheck = exports.CircleChecks = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const style_1 = require("../../ui/Text/style");
const style_2 = require("../../ui/Box/style");
const styled_1 = require("./styled");
const D = __importStar(require("../../ui/Display"));
const projectCategory_1 = require("../../../schemes/projectCategory");
function CheckBoxes({ name = 'check', styleTheme = 'inline', multiple = true, values = [], disabled = [], options = [], onChange, customStyle = { wrap: {}, box: {} }, }) {
    if (options.length > 0 && typeof options[0] === 'string') {
        options = (0, projectCategory_1.getOptionList)(options);
    }
    return ((0, jsx_runtime_1.jsx)(styled_1.CheckBoxWrap, { style: customStyle.wrap, styleTheme: styleTheme, children: options.length > 0 &&
            options.map((option, idx) => ((0, jsx_runtime_1.jsxs)(styled_1.Checkbox, { styleTheme: styleTheme, style: customStyle.box, children: [(0, jsx_runtime_1.jsx)("input", { id: `${name}_${idx}`, name: `${name}_${idx}`, type: multiple ? 'checkbox' : 'radio', value: option.value, checked: multiple ? values.filter((item) => item === option.value).length > 0 : values === option.value, disabled: disabled?.indexOf(option.value) >= 0, onChange: onChange }), (0, jsx_runtime_1.jsx)("label", { htmlFor: `${name}_${idx}`, children: option.text ?? option.value })] }, idx))) }));
}
exports.default = CheckBoxes;
/* ==============================
    [회원가입] 약관 선택
============================== */
const checkOffIcon = ((0, jsx_runtime_1.jsxs)("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [(0, jsx_runtime_1.jsx)("rect", { x: "0.5", y: "0.5", width: "23", height: "23", rx: "11.5", fill: "white" }), (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.7242 7.2652C18.0721 7.6398 18.0938 8.27246 17.7727 8.67828L11.443 16.6783C11.2808 16.8834 11.0524 17 10.8132 17C10.574 17 10.3456 16.8834 10.1834 16.6783L6.22732 11.6783C5.90623 11.2725 5.92792 10.6398 6.27576 10.2652C6.62361 9.8906 7.16589 9.9159 7.48698 10.3217L10.8132 14.5257L16.513 7.32172C16.8341 6.9159 17.3764 6.8906 17.7242 7.2652Z", fill: "#BDBDBD" }), (0, jsx_runtime_1.jsx)("rect", { x: "0.5", y: "0.5", width: "23", height: "23", rx: "11.5", stroke: "#E0E0E0" })] }));
const checkOnIcon = ((0, jsx_runtime_1.jsxs)("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [(0, jsx_runtime_1.jsx)("rect", { x: "0.5", y: "0.5", width: "23", height: "23", rx: "11.5", fill: "white" }), (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.7242 7.2652C18.0721 7.6398 18.0938 8.27246 17.7727 8.67828L11.443 16.6783C11.2808 16.8834 11.0524 17 10.8132 17C10.574 17 10.3456 16.8834 10.1834 16.6783L6.22732 11.6783C5.90623 11.2725 5.92792 10.6398 6.27576 10.2652C6.62361 9.8906 7.16589 9.9159 7.48698 10.3217L10.8132 14.5257L16.513 7.32172C16.8341 6.9159 17.3764 6.8906 17.7242 7.2652Z", fill: "#CC0041" }), (0, jsx_runtime_1.jsx)("rect", { x: "0.5", y: "0.5", width: "23", height: "23", rx: "11.5", stroke: "#CC0041" })] }));
function CircleChecks({ name = 'check', styleTheme = 'base', multiple = true, values = [], options = [], onChange, }) {
    return ((0, jsx_runtime_1.jsx)(D.FlexCols, { _gap: 16, children: options.map((option, idx) => {
            const checked = multiple ? values.filter((item) => item === option.value).length > 0 : values === option.value;
            return ((0, jsx_runtime_1.jsxs)(style_2.LabelBox, { children: [(0, jsx_runtime_1.jsx)("input", { id: `${name}_${idx}`, name: `${name}_${idx}`, value: option.value, type: 'checkbox', onChange: (e) => onChange(e) }), (0, jsx_runtime_1.jsx)("label", { htmlFor: `${name}_${idx}`, children: (0, jsx_runtime_1.jsxs)(D.FlexRows, { _items: "start", _content: "start", _gap: 8, children: [(0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [checked && checkOnIcon, !checked && checkOffIcon] }), (0, jsx_runtime_1.jsx)(style_2.Box, { _width: "calc(100% - 32px)", _padding: "0", children: (0, jsx_runtime_1.jsx)(style_1.Text, { _size: styleTheme === 'xl' ? 's' : 'base', _color: "gray8", _weight: styleTheme === 'xl' ? 'bold' : 'normal', children: option.text }) })] }) })] }, idx));
        }) }));
}
exports.CircleChecks = CircleChecks;
/* ==============================
    [새 프로젝트 등록] 상담 선택
============================== */
function ConsultCheck({ name = '', defaultValue = false, option = { text: '', value: '' }, onChange }) {
    return ((0, jsx_runtime_1.jsx)(style_2.Box, { _width: "100%", _height: 48, _radius: 2, _border: "1px", _bdcolor: defaultValue ? 'primaryMain' : 'gray2', _padding: "14px 16px", children: (0, jsx_runtime_1.jsxs)(style_2.LabelBox, { children: [(0, jsx_runtime_1.jsx)("input", { id: name, name: name, type: "checkbox", value: option.value, checked: defaultValue, onChange: (event) => {
                        onChange(event);
                    } }), (0, jsx_runtime_1.jsx)("label", { htmlFor: name, children: (0, jsx_runtime_1.jsxs)(D.FlexRows, { _gap: 8, _items: "center", children: [(0, jsx_runtime_1.jsx)("svg", { width: "12", height: "9", viewBox: "0 0 12 9", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { d: "M1 2.5L5.28571 7L11 1", stroke: defaultValue ? '#CC0041' : '#9E9E9E', strokeWidth: "1.5" }) }), (0, jsx_runtime_1.jsx)(style_1.Text, { _size: "xs", _color: defaultValue ? 'primaryMain' : 'gray8', children: option.text })] }) })] }) }));
}
exports.ConsultCheck = ConsultCheck;
