"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRecommendParnterList = void 0;
const _1 = require(".");
const urls = {
    partner: '/api/mypageClient/aircmnd',
};
function getRecommendParnterList(projectNo) {
    return _1.client.get(urls.partner, {
        params: {
            projectNo: projectNo,
        },
    });
}
exports.getRecommendParnterList = getRecommendParnterList;
