"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPartnerDepositHistory = exports.getClientDepositHistory = exports.confirmPayment = exports.requestPayment = exports.depositPayment = exports.getPartnerPaymentHistory = exports.getClientPaymentHistory = exports.getPaymentPrice = void 0;
const _1 = require(".");
const urls = {
    price: '/api/mypagePartner/requestPay',
    history: {
        client: '/api/mypageClient/requestPay4',
        partner: '/api/mypagePartner/requestPay2',
    },
    depositHistory: {
        client: '/api/mypageClient/yechiPay2',
        partner: '/api/mypagePartner/yechiPay',
    },
    request: '/api/mypagePartner/requestPay3',
    deposit: '/api/mypageClient/approvalPay',
    confirm: '/api/mypagePartner/depositCheck',
};
/* ==============================
    대금 지급 금액 정보
============================== */
function getPaymentPrice(partnerNo, projectNo) {
    return _1.client.get(urls.price, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.getPaymentPrice = getPaymentPrice;
/* ==============================
    대금 지급 회차 정보
============================== */
function getClientPaymentHistory(partnerNo, projectNo) {
    return _1.client.get(urls.history.client, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.getClientPaymentHistory = getClientPaymentHistory;
function getPartnerPaymentHistory(partnerNo, projectNo) {
    return _1.client.get(urls.history.partner, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.getPartnerPaymentHistory = getPartnerPaymentHistory;
/* ==============================
    대금 지급 승인
============================== */
function depositPayment(partnerNo, projectNo) {
    return _1.client.get(urls.deposit, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.depositPayment = depositPayment;
/* ==============================
    대금 지급  요청
============================== */
function requestPayment(partnerNo, projectNo) {
    return _1.client.get(urls.request, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.requestPayment = requestPayment;
/* ==============================
    대금 지급  확인
============================== */
function confirmPayment(partnerNo, projectNo) {
    return _1.client.get(urls.confirm, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.confirmPayment = confirmPayment;
/* ==============================
    예치금 정보
============================== */
function getClientDepositHistory(partnerNo, projectNo) {
    return _1.client.get(urls.depositHistory.client, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.getClientDepositHistory = getClientDepositHistory;
function getPartnerDepositHistory(partnerNo, projectNo) {
    return _1.client.get(urls.depositHistory.partner, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.getPartnerDepositHistory = getPartnerDepositHistory;
