"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.linkToAccount = exports.unsubscribe = exports.findPwd = exports.findPwdAuth = exports.findId = exports.findIdAuth = exports.userLogin = exports.phoneAuth = exports.joinEmailAuth = exports.joinAuth = exports.joinIdChck = exports.join = void 0;
const _1 = require(".");
const urls = {
    join: '/api/user/joinProc',
    joinAuth: '/api/user/joinUserPhoneCheck',
    joinEmailAuth: '/api/user/joinUserEmailCheck',
    joinIdCheck: '',
    phoneAuth: '/api/user/phone/auth',
    login: '/api/user/loginProc',
    findIdAuth: '/api/user/sendAuthNum',
    findId: '/api/user/findIdProc',
    findPwd: '/api/user/resetPwd',
    findPwdAuth: '/api/user/findPwdProc',
    unsubscribe: '/api/mypageClient/userDelete',
    linkToAccount: 'api/mypage/linkToAccount',
};
// 회원 가입
function join(userData) {
    return _1.client.post(urls.join, userData);
}
exports.join = join;
// 회원가입 유효 아이디 체크
function joinIdChck(userId) {
    return _1.client.post(urls.joinIdCheck, { params: { userId: userId } });
}
exports.joinIdChck = joinIdChck;
// 회원가입 연락처 인증
function joinAuth(userTel, isAuth = false) {
    return _1.client.post(urls.joinAuth, { userPhone: userTel, isAuth: isAuth });
}
exports.joinAuth = joinAuth;
// 회원가입 이메일 인증
function joinEmailAuth(userEmail, isAuth = false) {
    return _1.client.post(urls.joinEmailAuth, { userEmail: userEmail, isAuth: isAuth });
}
exports.joinEmailAuth = joinEmailAuth;
// 연락처 인증
function phoneAuth(userTel) {
    return _1.client.post(`${urls.phoneAuth}/${userTel}`);
}
exports.phoneAuth = phoneAuth;
function userLogin(loginData) {
    return _1.client.post(urls.login, {
        userId: loginData.id,
        userPwd: loginData.pwd,
    });
}
exports.userLogin = userLogin;
/* ==============================
    아이디 찾기
============================== */
function findIdAuth(tel) {
    return _1.client.get(urls.findIdAuth, { params: { userPhone: tel } });
}
exports.findIdAuth = findIdAuth;
function findId(tel) {
    return _1.client.get(urls.findId, { params: { userPhone: tel } });
}
exports.findId = findId;
/* ==============================
    비밀번호 찾기
============================== */
function findPwdAuth(id, tel) {
    return _1.client.get(urls.findPwdAuth, { params: { userId: id, userPhone: tel } });
}
exports.findPwdAuth = findPwdAuth;
function findPwd(id, pwd) {
    return _1.client.post(urls.findPwd, { userId: id, userPwd: pwd });
}
exports.findPwd = findPwd;
function unsubscribe(params) {
    return _1.client.get(urls.unsubscribe, {
        params: params,
    });
}
exports.unsubscribe = unsubscribe;
/* ==============================
    회원 계정 연동
============================== */
function linkToAccount(userNo, provider, providerId) {
    return _1.client.post(urls.linkToAccount, { userNo, provider, providerId });
}
exports.linkToAccount = linkToAccount;
