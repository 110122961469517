"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHistory = exports.locationState = void 0;
const recoil_1 = require("recoil");
const _1 = require(".");
const MAX_LEN = 10;
exports.locationState = (0, recoil_1.atom)({
    key: _1.stateKeys.location,
    default: null,
    effects: [(0, _1.sessionStorageEffect)({ key: _1.stateKeys.location })],
});
function useHistory() {
    const [locations, setLocations] = (0, recoil_1.useRecoilState)(exports.locationState);
    function saveHistory(path) {
        const locations = sessionStorage.getItem(_1.stateKeys.location);
        if (!locations) {
            setLocations({
                popstate: false,
                history: [path],
            });
        }
        else {
            let newH = [...JSON.parse(locations).history];
            if (newH[newH.length - 1] !== path) {
                newH.push(path);
            }
            if (newH.length >= MAX_LEN) {
                newH = newH.slice(1, MAX_LEN);
            }
            setLocations({
                popstate: false,
                history: newH,
            });
        }
    }
    function savePopState(path) {
        // if(locations && Array.isArray(locations.history)) {
        //     for(let i = locations.history.length-1; i >= 0; i--) {
        //         if(locations.history[i] === path) {
        //             setLocations({
        //                 popstate: true,
        //                 history: locations.history.slice(0, i+1)
        //             });
        //             break;
        //         }
        //     }
        // }
        const locations = sessionStorage.getItem(_1.stateKeys.location);
        if (!locations) {
            return;
        }
        const history = JSON.parse(locations).history;
        for (let i = history.length - 1; i >= 0; i--) {
            if (history[i] === path) {
                setLocations({
                    popstate: true,
                    history: history.slice(0, i + 1),
                });
                break;
            }
        }
    }
    function isBackPage(path) {
        if (!locations || !locations.popstate) {
            return false;
        }
        return locations.history[locations.history.length - 1] === path;
    }
    function resetPopState() {
        if (!locations) {
            return;
        }
        else {
            const nLocations = { ...locations };
            nLocations.popstate = false;
            setLocations(nLocations);
        }
    }
    return {
        locations: locations,
        saveHistory: saveHistory,
        savePopState: savePopState,
        resetPopState: resetPopState,
        isBackPage: isBackPage,
    };
}
exports.useHistory = useHistory;
