"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.delPortfolio = exports.newPortfolio = exports.getPartnerPortfolio = exports.getPortfolioPost = exports.getPortfolioHomeList = exports.getPortfolioList = void 0;
const _1 = require(".");
const urls = {
    portfolioList: '/api/portfolio/findPortfolio',
    portfolioHomeList: '/api/index/portfolioList',
    portfolioPost: '/api/portfolio/detail',
    partnerPortfolio: '/api/portfolio/indv',
    newPortfolilo: '/api/portfolio/registration',
    delPortfolio: '/api/mypagePartner/deletePortfolio',
};
function getPortfolioList(params) {
    return _1.client.get(urls.portfolioList, { params: params });
}
exports.getPortfolioList = getPortfolioList;
// 홈 포트폴리오 목록
function getPortfolioHomeList(userNo) {
    return _1.client.get(urls.portfolioHomeList, { params: { userNo: userNo } });
}
exports.getPortfolioHomeList = getPortfolioHomeList;
// 포트폴리오 상세
function getPortfolioPost(postNo, userNo) {
    return _1.client.get(urls.portfolioPost, { params: { portfolioNo: postNo, userNo: userNo } });
}
exports.getPortfolioPost = getPortfolioPost;
// 파트너 포트폴리오 목록
function getPartnerPortfolio(userNo, page, limitOption = true) {
    return _1.client.get(urls.partnerPortfolio, { params: { userNo: userNo, page: page, limitOption: limitOption } });
}
exports.getPartnerPortfolio = getPartnerPortfolio;
// 포트폴리오 등록 및 수정
function newPortfolio(params) {
    return _1.client.post(urls.newPortfolilo, params);
}
exports.newPortfolio = newPortfolio;
// 포트폴리오 삭제
function delPortfolio(partnerNo, portfolioNo) {
    return _1.client.get(urls.delPortfolio, {
        params: {
            partnerNo: partnerNo,
            portfolioNo: portfolioNo,
        },
    });
}
exports.delPortfolio = delPortfolio;
