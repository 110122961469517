"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contents = exports.Label = exports.LabelWrap = exports.Wrapper = exports.StyledFieldset = exports.Inform = exports.RequiredLabel = exports.AccentLabel = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const descriptionCss = (0, styled_components_1.css) `
  gap: 40px;
`;
exports.AccentLabel = styled_components_1.default.div `
  span {
    position: relative;
    padding-left: 14px;
    font-size: 15px;
    font-weight: 400;

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 2px;
      height: 12px;
      background-color: ${(props) => props.theme.colors.primaryMain};
    }
  }

  @media (${(props) => props.theme.media.tablet}) {
    span {
      font-size: ${(props) => props.theme.fontSizes.s};
    }
  }
`;
exports.RequiredLabel = styled_components_1.default.div `
  display: flex;
  flex-direction: row;
  gap: 2px;

  &::after {
    content: '*';
    color: ${(props) => props.theme.colors.primaryMain};
  }

  @media (${(props) => props.theme.media.tablet}) {
    span {
      font-size: ${(props) => props.theme.fontSizes.s};
    }
  }
`;
const accentLabelCss = (0, styled_components_1.css) `
  > .label {
    span {
      position: relative;
      padding-left: 14px;
      font-size: 15px;
      font-weight: 400;

      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: 2px;
        height: 12px;
        background-color: ${(props) => props.theme.colors.primaryMain};
      }
    }
  }

  @media (${(props) => props.theme.media.tablet}) {
    gap: 16px;
  }
`;
const requiredLabelCss = (0, styled_components_1.css) `
  > .label {
    display: flex;
    flex-direction: row;
    gap: 2px;

    &::after {
      content: '*';
      color: ${(props) => props.theme.colors.primaryMain};
    }

    @media (${(props) => props.theme.media.tablet}) {
      span {
        font-size: 14px;
      }
    }
  }
`;
const lightCss = (0, styled_components_1.css) `
  gap: 12px;

  .label {
    span {
      font-size: ${(props) => props.theme.fontSizes.s};
    }
  }
`;
exports.Inform = styled_components_1.default.p `
  white-space: pre-wrap;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.gray6};
  font-size: ${(props) => props.theme.fontSizes.xxs};
`;
exports.StyledFieldset = styled_components_1.default.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > .label {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    span {
      color: ${(props) => props.theme.colors.gray9};
      font-size: ${(props) => props.theme.fontSizes.l};
      font-weight: ${(props) => props.theme.fontWeights.medium};
    }

    .inform {
      white-space: pre-wrap;
      line-height: 1.5;
      color: ${(props) => props.theme.colors.gray6};
      font-size: ${(props) => props.theme.fontSizes.xxs};
    }
  }

  > .input {
    width: 100%;
  }

  @media (${(props) => props.theme.media.tablet}) {
    gap: 12px;
  }

  ${(props) => props.styleTheme === 'light' && lightCss}
  ${(props) => props.styleTheme === 'requiredLabel' && requiredLabelCss}
    ${(props) => props.styleTheme === 'accentLabel' && accentLabelCss}
    ${(props) => props.styleTheme === 'description' && descriptionCss}
`;
exports.Wrapper = styled_components_1.default.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${(props) => props.theme.media.tablet}) {
    gap: 12px;
  }
`;
exports.LabelWrap = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
exports.Label = styled_components_1.default.span `
  font-size: ${(props) => props.theme.fontSizes[props._size ?? 'l']};
  font-weight: ${(props) => props.theme.fontWeights[props._weight ?? 'medium']};

  @media (${(props) => props.theme.media.tablet}) {
    font-size: ${(props) => props.theme.fontSizes[props._m_size ?? props._size ?? 's']};
  }

  ${(props) => props._required &&
    (0, styled_components_1.css) `
      display: flex;
      align-items: center;
      gap: 4px;

      &::after {
        content: '*';
        display: inline-block;
        color: ${(props) => props.theme.colors.primaryMain};
        transform: translateY(-4px);
      }
    `}
`;
exports.Contents = styled_components_1.default.div `
  width: 100%;
`;
