"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAlert = exports.alertState = void 0;
const react_router_dom_1 = require("react-router-dom");
const recoil_1 = require("recoil");
const _1 = require(".");
exports.alertState = (0, recoil_1.atom)({
    key: _1.stateKeys['alert'],
    default: {
        mode: 'alert',
        active: false,
        text: '',
        onConfirm: () => { },
        onCancel: () => { },
        overlayClick: true,
    },
});
function useAlert() {
    const [alert, setAlert] = (0, recoil_1.useRecoilState)(exports.alertState);
    const closeAlert = (0, recoil_1.useResetRecoilState)(exports.alertState);
    const navigate = (0, react_router_dom_1.useNavigate)();
    function setTextAlert(text = '') {
        setAlert({
            mode: 'alert',
            active: true,
            text: text,
            onConfirm: () => {
                closeAlert();
            },
            onCancel: closeAlert,
            overlayClick: true,
        });
    }
    function setPathAlert(text = '', path = '/', overlayClick = false) {
        setAlert({
            mode: 'alert',
            active: true,
            text: text,
            onConfirm: () => {
                navigate(path);
                closeAlert();
            },
            onCancel: closeAlert,
            overlayClick: overlayClick,
        });
    }
    function setActionAlert(text = '', onClick = () => { }, onCancelClick = null, overlayClick = false) {
        setAlert({
            mode: 'alert',
            active: true,
            text: text,
            onConfirm: () => {
                onClick();
                closeAlert();
            },
            onCancel: () => {
                if (onCancelClick)
                    onCancelClick();
                closeAlert();
            },
            overlayClick: overlayClick,
        });
    }
    function setConfirm(option = {
        text: '',
        onConfirm: () => { },
        onCancel: () => { },
    }) {
        setAlert({
            mode: 'confirm',
            active: true,
            text: option.text,
            onConfirm: () => {
                option.onConfirm();
                closeAlert();
            },
            onCancel: () => {
                option.onCancel();
                closeAlert();
            },
            overlayClick: false,
        });
    }
    return {
        textAlert: setTextAlert,
        pathAlert: setPathAlert,
        actionAlert: setActionAlert,
        confirm: setConfirm,
    };
}
exports.useAlert = useAlert;
