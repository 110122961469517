"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useViewPortHeight = exports.useScrollUp = exports.useDropDown = void 0;
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
/* ==============================
    UI-DROPDOWN
    - [사용] 드롭다운 메뉴, 컨텐츠
============================== */
function useDropDown(contentsClass = '') {
    const [active, setActive] = (0, react_1.useState)(false);
    const onTargetClick = (event) => {
        if (event)
            event.preventDefault();
        if (active === false) {
            window.addEventListener('click', onWindowClick);
        }
        setActive(!active);
    };
    const onWindowClick = (0, react_1.useCallback)((event) => {
        event.preventDefault();
        if (contentsClass.length > 0) {
            const target = event.target;
            const classNames = target.className;
            if ((typeof classNames === 'string' && classNames.includes(contentsClass)) ||
                target.closest(`.${contentsClass}`)) {
                return;
            }
        }
        setActive(false);
        window.removeEventListener('click', onWindowClick);
    }, []);
    return [active, onTargetClick];
}
exports.useDropDown = useDropDown;
/* ==============================
    UI-SCROLLUP
    - [사용] 윈도우 스크롤 처리
============================== */
function useScrollUp() {
    const bodyRef = (0, react_1.useRef)(null);
    const location = (0, react_router_dom_1.useLocation)();
    (0, react_1.useEffect)(() => {
        if (bodyRef.current)
            bodyRef.current.scrollTo(0, 0);
    }, [location]);
    return [bodyRef];
}
exports.useScrollUp = useScrollUp;
/* ==============================
    UI-VIEWPORT HEIGHT
    - [사용] 브라우저 실제 height 측정
============================== */
function useViewPortHeight() {
    const [vh, setVh] = (0, react_1.useState)(window.innerHeight);
    const resizeVh = (0, react_1.useCallback)((e) => {
        const vh = window.innerHeight;
        setVh(vh);
    }, []);
    (0, react_1.useEffect)(() => {
        setVh(window.innerHeight);
        window.addEventListener('resize', resizeVh);
        return () => {
            window.removeEventListener('resize', resizeVh);
        };
    }, []);
    return [vh];
}
exports.useViewPortHeight = useViewPortHeight;
