"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMyProjectDetails = exports.useMatchProjectDetailsQuery = exports.useInfiniteMatchProjectQuery = exports.mypageKeys = void 0;
const react_query_1 = require("@tanstack/react-query");
const mypage_1 = require("../../service/client/mypage");
exports.mypageKeys = {
    all: 'mypage',
    project: ['mypage', 'project'],
    projectList: (params) => [...exports.mypageKeys.project, 'list', params],
    projectSession: (params) => [...exports.mypageKeys.project, 'session', params],
    projectDetails: (params) => [exports.mypageKeys.all, 'project_details', params],
};
// 마이페이지 > 전체 프로젝트 : 카테고리별 목록
const useInfiniteMatchProjectQuery = (params) => (0, react_query_1.useInfiniteQuery)({
    queryKey: exports.mypageKeys.projectList(params),
    queryFn: async ({ pageParam = 1 }) => {
        if (!params.category || params.category.length <= 0)
            return null;
        if (params.isClient)
            return await (0, mypage_1.getClientAllProjects)(params.userNo, params.category, pageParam);
        else
            return await (0, mypage_1.getPartnerAllProjects)(params.userNo, params.category, pageParam);
    },
    getNextPageParam: (lastPage, _) => {
        return lastPage && lastPage?.data?.pageCode === 200;
    },
    enabled: false,
    refetchOnWindowFocus: true,
});
exports.useInfiniteMatchProjectQuery = useInfiniteMatchProjectQuery;
// 마이페이지 > 전체 프로젝트 > 진행 상세 데이터
const useMatchProjectDetailsQuery = (params) => (0, react_query_1.useQuery)({
    queryKey: exports.mypageKeys.projectSession(params),
    queryFn: async () => {
        if (params.isClient)
            return await (0, mypage_1.getClientProjectDetail)(params.userNo, params.projectNo, params.type);
        return await (0, mypage_1.getPartnerProjectDetail)(params.userNo, params.projectNo, params.type);
    },
    select: (data) => {
        return {
            session: params.type,
            data: data.data,
        };
    },
    enabled: false,
    refetchOnWindowFocus: true,
});
exports.useMatchProjectDetailsQuery = useMatchProjectDetailsQuery;
const useMyProjectDetails = (params) => (0, react_query_1.useQuery)({
    queryKey: exports.mypageKeys.projectDetails(params ? params.type.toString() : '-1'),
    refetchInterval: 1000 * 60,
    refetchOnMount: false,
    refetchIntervalInBackground: true,
    queryFn: async () => {
        if (params === null) {
            return null;
        }
        const response = params.role === 'client'
            ? await (0, mypage_1.getClientProjectDetail)(params.userNo, params.projectNo, params.type)
            : await (0, mypage_1.getPartnerProjectDetail)(params.userNo, params.projectNo, params.type);
        if (!response || response.status !== 200) {
            return null;
        }
        return response.data;
    },
    select: (data) => data
        ? {
            code: data.code ?? 0,
            arrayData: data.arrayData ?? [],
            message: data.message ?? '',
        }
        : null,
});
exports.useMyProjectDetails = useMyProjectDetails;
