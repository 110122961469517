"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ProjectCard = __importStar(require("../ProjectCard"));
const S = __importStar(require("./style"));
const style_1 = require("../../ui/Box/style");
const style_2 = require("../../ui/Text/style");
const style_3 = require("../../ui/Line/style");
const App_1 = require("../../../App");
function ProjectMatchCard({ session = false, apply = false, sessionNaviation = [], activeSession, defaultSession, handleSessionChange, applyText = '', onApplyClick, data, children, }) {
    const isTablet = (0, react_1.useContext)(App_1.ScreenContext).tablet;
    function onSessionVisibleClick() {
        if (activeSession < 0)
            handleSessionChange(defaultSession);
        else
            handleSessionChange(-1);
    }
    return ((0, jsx_runtime_1.jsxs)(S.Card, { children: [(0, jsx_runtime_1.jsxs)("div", { className: "main", children: [(0, jsx_runtime_1.jsx)(ProjectCard.Session, { data: data }), (0, jsx_runtime_1.jsxs)(S.CardBtns, { children: [session && ((0, jsx_runtime_1.jsx)(S.DetailBtn, { active: activeSession >= 0, hasApply: apply, onClick: () => onSessionVisibleClick(), children: "\uC9C4\uD589\uC0C1\uC138" })), apply && ((0, jsx_runtime_1.jsx)(style_1.Box, { as: "button", _width: 92, _m_width: 'calc(50% - 4px)', _height: 36, _radius: 2, _padding: "8px 12px", _bgcolor: "gray8", _textalign: "center", onClick: onApplyClick, children: (0, jsx_runtime_1.jsx)(style_2.Text, { _size: "xs", _color: "white", children: applyText }) }))] })] }), activeSession >= 0 && ((0, jsx_runtime_1.jsxs)("div", { className: "session", children: [!isTablet && (0, jsx_runtime_1.jsx)(style_3.Line, { styleTheme: "lightGray", _gap: "32px" }), (0, jsx_runtime_1.jsx)(S.SessionWrap, { children: sessionNaviation.map((session, idx) => ((0, jsx_runtime_1.jsxs)(S.SessionBox, { "data-idx": idx, active: idx === activeSession, children: [(0, jsx_runtime_1.jsx)(S.SessionNav, { index: idx, active: idx === activeSession, children: (0, jsx_runtime_1.jsx)("button", { onClick: () => handleSessionChange(idx), children: session }) }), (0, jsx_runtime_1.jsx)(S.SessionContents, { active: idx === activeSession, children: children })] }, idx))) })] }))] }));
}
exports.default = ProjectMatchCard;
