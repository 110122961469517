"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sessionStorageEffect = exports.localStorageEffect = exports.stateKeys = void 0;
/* ==============================
    COMMON_STATE_KEYS
============================== */
exports.stateKeys = {
    user: 'USER',
    admin: 'ADMIN',
    alarm: 'ALARM',
    alarmList: 'ALARM_LIST',
    alert: 'ALERT',
    mobileTitle: 'MOBILE_TITLE',
    mobileHistory: 'MOBILE_HISTORY',
    /* === 프로젝트/포트폴리오 === */
    projectList: 'PROJECT_LIST',
    portfolioList: 'PORTFOLIO_LIST',
    /* === 프로젝트 등록 === */
    projectForm: 'PROJECT_FORM',
    /* === 메인화면 목록 === */
    homeProject: 'HOME_PROJECT',
    homePortfolio: 'HOME_PORTFOLIO',
    /* === 공지사항 목록 === */
    notice: 'NOTICE_LIST',
    /* === 마이페이지 관련 === */
    myMainPage: 'MY_MAIN_PAGE',
    myInformation: 'MY_INFORMATION',
    mySession: 'MY_SESSION',
    /* === 팝업 관련 === */
    paymentPopup: 'PAYMENT_POPUP',
    meetingRequestPopup: 'MEETING_REQUEST_POPUP',
    applyPopup: 'APPLY_POPUP',
    meetingConfirmPopup: 'MEETING_CONFIRM_POPUP',
    fileViewerPopup: 'FILE_VIEWER_POPUP',
    /* === PMS 관련 데이터 === */
    pmsSchedule: 'PMS_SCHEDULE',
    pmsGuide: 'PMS_GUIDE',
    pmsManager: 'PMS_ADMIN',
    pmsTour: 'PMS_TOUR',
    /* === ui 관련 데이터 === */
    pcHeaderUi: 'HEADER_UI',
    /* === ui 관련 데이터 === */
    location: 'LOCATION',
};
const localStorageEffect = (key) => ({ setSelf, onSet }) => {
    const savedData = localStorage.getItem(key);
    if (savedData !== null)
        setSelf(JSON.parse(savedData));
    else
        setSelf(null);
    onSet((newValue) => {
        if (newValue === null)
            localStorage.removeItem(key);
        else
            localStorage.setItem(key, JSON.stringify(newValue));
    });
};
exports.localStorageEffect = localStorageEffect;
const sessionStorageEffect = ({ key, hour, defaultValue }) => ({ setSelf, onSet }) => {
    const savedData = sessionStorage.getItem(key);
    if (savedData !== null) {
        const data = JSON.parse(savedData);
        if (hour && data.date) {
            let limitDate = new Date(data.date);
            limitDate.setHours(limitDate.getHours() + hour);
            if (limitDate < new Date())
                setSelf(null);
            else
                setSelf(data);
        }
        else {
            setSelf(data);
        }
    }
    else {
        setSelf(defaultValue || null);
    }
    onSet((newValue) => {
        sessionStorage.setItem(key, JSON.stringify(newValue));
    });
};
exports.sessionStorageEffect = sessionStorageEffect;
