"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
require("react-lazy-load-image-component/src/effects/blur.css");
function ImageEl({ url, width, height, alt, customStyle }) {
    const boxRef = (0, react_1.useRef)(null);
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [error, setError] = (0, react_1.useState)(false);
    const [imgWidth, setImgwidth] = (0, react_1.useState)('auto');
    const [imgHeight, setImgHeight] = (0, react_1.useState)('auto');
    const [loadedSrc, setLoadedSrc] = (0, react_1.useState)('');
    const adjustSize = (0, react_1.useCallback)((url = '', reload = true) => {
        if (!boxRef.current)
            return;
        const rect = boxRef.current.getBoundingClientRect();
        const w = Math.round(rect.width);
        const h = Math.round(rect.height);
        const boxRate = rect.width / rect.height;
        let img;
        if (!reload) {
            setLoading(true);
            img = new Image();
            img.src = url;
            img.onload = () => {
                const imgRate = img.width / img.height;
                if (boxRate >= imgRate) {
                    setImgwidth(w + 'px');
                    setImgHeight(w * (img.height / img.width) + 'px');
                }
                else {
                    setImgHeight(h + 'px');
                    setImgwidth(w * imgRate + 'px');
                }
                setLoadedSrc(url);
                setLoading(false);
            };
            img.onerror = () => {
                setError(true);
                setLoading(false);
            };
        }
        else {
            img = boxRef.current.querySelector('img');
            if (!img) {
                return;
            }
            const imgRate = img.getBoundingClientRect().width / img.getBoundingClientRect().height;
            if (boxRate >= imgRate) {
                setImgwidth(w + 'px');
                setImgHeight(w * (img.height / img.width) + 'px');
            }
            else {
                setImgHeight(h + 'px');
                setImgwidth(w * imgRate + 'px');
            }
        }
    }, []);
    let resizeTimeout;
    const resizeImg = (0, react_1.useCallback)(() => {
        if (resizeTimeout) {
            clearTimeout(resizeTimeout);
        }
        resizeTimeout = setTimeout(() => {
            adjustSize(url);
        }, 200);
        adjustSize();
    }, []);
    (0, react_1.useEffect)(() => {
        if (boxRef.current && url) {
            adjustSize(url, false);
            window.addEventListener('resize', resizeImg);
        }
        return () => {
            window.removeEventListener('resize', resizeImg);
        };
    }, [url]);
    return ((0, jsx_runtime_1.jsxs)("div", { ref: boxRef, style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: width,
            height: height,
            overflow: 'hidden',
            ...(customStyle && customStyle),
        }, children: [!loading && !error && (0, jsx_runtime_1.jsx)("img", { src: loadedSrc, width: imgWidth, height: imgHeight, alt: alt }), !loading && error && ((0, jsx_runtime_1.jsx)("div", { style: {
                    width: '100%',
                    height: '100%',
                    background: '#EFEFEF',
                } }))] }));
}
const CustomImage = react_1.default.memo(ImageEl, (prev, next) => prev.url === next.url);
exports.default = CustomImage;
