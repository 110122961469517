"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionWrap = exports.FloatingNav = exports.LayoutWrap = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const utils_1 = require("../../../themes/utils");
exports.LayoutWrap = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
  background-color: #fff;
  padding: 40px 0 160px;

  @media (${(props) => props.theme.media.tablet}) {
    gap: 0;
    padding: 0;
  }
`;
const bgNextMotion = (0, styled_components_1.keyframes) `
    0% { left: -100%; };
    100% { left: 0; };
`;
const bgPrevMotion = (0, styled_components_1.keyframes) `
    0% { left: 100%; };
    100% { left: 0 };
`;
exports.FloatingNav = styled_components_1.default.div `
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    background-color: #FFF;
        
    ul {
            display: flex;
            gap: 4px;
            width: 100%;

        li { 
            z-index: 21;
            position: relative;
            width ${(props) => `calc(${parseFloat(100 / props.navLength + '').toFixed(2)}% - ${parseFloat((4 * (props.navLength - 1)) / props.navLength + '').toFixed(2)}px)`};
            height: 68px;
            text-align: center;
            white-space: nowrap;
            line-height: 68px;
            background-color: ${(props) => props.theme.colors.gray2};
            transition: background-color .2s linear;
            cursor: pointer;
            overflow: hidden;

            &:hover {
                background-color: ${(props) => props.theme.colors.primary8};
                span {
                    font-weight: ${(props) => props.theme.fontWeights.normal};
                    color: #FFF;
                }
            }

            &::after,
            &::before {
                z-index: 22;
                content: '';
                position: absolute;
                bottom: 0;
                left: -100%;
                width: 100%;
                height: 100%;
                background-color: ${(props) => props.theme.colors.primary8};
            }
            &::before {
                left: 100%;
            }

            span {
                z-index: 23;
                position: relative;
                font-size: ${(props) => props.theme.fontSizes.s};
                font-weight: ${(props) => props.theme.fontWeights.bold};
                color: ${(props) => props.theme.colors.gray8};
            }

            &:nth-child(${(props) => props.active + 1}) {
                span {
                    font-weight: ${(props) => props.theme.fontWeights.normal};
                    color: #FFF;
                }

                ${(props) => props.direction === 'next' &&
    (0, styled_components_1.css) `
                    &::after {
                      animation: ${bgNextMotion} 0.15s ease-in-out forwards;
                    }
                  `}
                ${(props) => props.direction === 'prev' &&
    (0, styled_components_1.css) `
                    &::before {
                      animation: ${bgPrevMotion} 0.15s ease-in-out forwards;
                    }
                  `}
            }
        }
    }

    @media(${(props) => props.theme.media.tablet}) {
        left: 0;
        width: 100%;
        overflow-x: scroll;
        justify-content: start;
        align-items: end;
        padding: 0;
        ${utils_1.nonScrollbar}

        ul {
            flex-wrap: nowrap;
            width: auto;
            gap: 0;

            li {
                width: 140px !important;
                height: 44px;
                line-height: 44px;
                background-color: #FFF;

                &:hover {
                    background-color: #FFF;
                    span {
                        color: ${(props) => props.theme.colors.gray5};
                        font-weight: ${(props) => props.theme.fontWeights.bold};
                    }
                }

                span {
                    color: ${(props) => props.theme.colors.gray5};
                }

                &::before,
                &::after {
                    height: 2px;
                    background-color: #000;
                }

                &:nth-child(${(props) => props.active + 1}) {
                    span {
                        color: #000;
                        font-weight: ${(props) => props.theme.fontWeights.bold};
                    }
                }
            }
        }
    }

    ${(props) => props.floating &&
    (0, styled_components_1.css) `
        z-index: 89;
        position: fixed;
        top: 0;
        left: 0;
        height: 80px;
        box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);

        ul {
          max-width: 1080px;
          padding: 0 16px;
        }

        @media (${props.theme.media.tablet}) {
          top: 56px;
          height: 60px;

          ul {
            padding: 0;
          }
        }
      `}

`;
exports.SectionWrap = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  gap: 60px;
  background-color: #fff;

  > div {
    width: 100%;
  }

  @media (${(props) => props.theme.media.tablet}) {
    width: calc(100% + 32px);
    transform: translateX(-16px);
    gap: 16px;
    padding: 16px 0;
    background-color: ${(props) => props.theme.colors.gray2};
  }
`;
