"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Display_1 = require("../../Display");
const Icons_1 = require("../Icons");
function IconLoading({ loading = false, _height = '100%', styleTheme = 'spinner', loadingElement, children, }) {
    if (loading) {
        if (loadingElement)
            return loadingElement;
        return ((0, jsx_runtime_1.jsxs)(Display_1.FlexRows, { _width: "100%", _height: _height, _content: "center", _items: "center", children: [styleTheme === 'spinner' && (0, jsx_runtime_1.jsx)(Icons_1.LoadingSpinner, {}), styleTheme === 'dots' && (0, jsx_runtime_1.jsx)(Icons_1.LoadingDots, {}), styleTheme === 'colorDots' && (0, jsx_runtime_1.jsx)(Icons_1.LoadingColorDots, {})] }));
    }
    return children;
}
exports.default = IconLoading;
