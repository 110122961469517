"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorDots = exports.Dots = exports.Spinner = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const gray = 'rgba(224, 224, 224, .8)';
const red = 'rgba(204, 0, 65, .5)';
const rotate = (0, styled_components_1.keyframes) `
    0%   {transform: rotate(0deg)}
    100%   {transform: rotate(360deg)}
`;
const prixClipFix = (0, styled_components_1.keyframes) `
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
`;
exports.Spinner = styled_components_1.default.div `
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  animation: ${rotate} 1s linear infinite;

  div {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid ${gray};
    animation: ${prixClipFix} 2s linear infinite;
  }

  div:nth-child(2) {
    inset: 8px;
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: ${red};
  }
`;
const dot1 = (0, styled_components_1.keyframes) `
    0% {transform: scale(0);}
    100% {transform: scale(1);}
`;
const dot2 = (0, styled_components_1.keyframes) `        
    0% {transform: translate(0, 0);}
    100% {transform: translate(24px, 0);}
`;
const dot3 = (0, styled_components_1.keyframes) `
    0% { transform: scale(1); }
    100% { transform: scale(0); }
`;
exports.Dots = styled_components_1.default.div `
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${gray};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 8px;
    animation: ${dot1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: ${dot2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: ${dot2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: ${dot3} 0.6s infinite;
  }
`;
const shadowPulse = (0, styled_components_1.keyframes) `
    33% {
        background: ${gray};
        box-shadow: -20px 0 ${red}, 20px 0 ${gray};
    }
    66% {
        background: ${red};
        box-shadow: -20px 0 ${gray}, 20px 0 ${gray};
    }
    100% {
        background: ${gray};
        box-shadow: -20px 0 ${gray}, 20px 0 ${red};
    }
`;
exports.ColorDots = styled_components_1.default.div `
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 12px auto;
  position: relative;
  background: ${gray};
  box-shadow: -20px 0 ${gray}, 20px 0 ${gray};
  box-sizing: border-box;
  animation: ${shadowPulse} 2s linear infinite;
`;
