"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const getIsLaptop = (windowWidth) => windowWidth <= 1024;
const getIsTablet = (windowWidth) => windowWidth <= 902;
const getIsTabletSmall = (windowWidth) => windowWidth <= 768;
const getIsMobile = (windowWidth) => windowWidth <= 425;
/* ==============================
    MEDIAQUERY
    - [사용] 브라우저 viewport width 측정
============================== */
function useMediaQuery() {
    const [isTablet, setIsTablet] = (0, react_1.useState)(getIsTablet(window.innerWidth));
    const [isTabletSmall, setIsTabletSmall] = (0, react_1.useState)(getIsTabletSmall(window.innerWidth));
    const [isMobile, setIsMobile] = (0, react_1.useState)(getIsMobile(window.innerWidth));
    const [isLaptop, setIsLaptop] = (0, react_1.useState)(getIsLaptop(window.innerWidth));
    (0, react_1.useEffect)(() => {
        window.addEventListener('resize', function () {
            const wWidth = window.innerWidth;
            setIsLaptop(getIsLaptop(wWidth));
            setIsTablet(getIsTablet(wWidth));
            setIsTabletSmall(getIsTabletSmall(wWidth));
            setIsMobile(getIsMobile(wWidth));
        });
    }, []);
    return [isLaptop, isTablet, isTabletSmall, isMobile];
}
exports.default = useMediaQuery;
