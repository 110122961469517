"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileViewerPopupState = exports.applyPopupState = exports.MeetingConfirmPopupState = exports.meetingRequestPopupState = exports.paymentPopupState = void 0;
const recoil_1 = require("recoil");
const _1 = require(".");
exports.paymentPopupState = (0, recoil_1.atom)({
    key: _1.stateKeys['paymentPopup'],
    default: null,
});
exports.meetingRequestPopupState = (0, recoil_1.atom)({
    key: _1.stateKeys['meetingRequestPopup'],
    default: null,
});
exports.MeetingConfirmPopupState = (0, recoil_1.atom)({
    key: _1.stateKeys['meetingConfirmPopup'],
    default: null,
});
/* ==============================
    지원서 지정 팝업
============================== */
exports.applyPopupState = (0, recoil_1.atom)({
    key: _1.stateKeys['applyPopup'],
    default: null,
});
exports.fileViewerPopupState = (0, recoil_1.atom)({
    key: _1.stateKeys['fileViewerPopup'],
    default: null,
});
