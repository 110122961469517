"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.alarmListState = exports.alarmState = void 0;
const _1 = require(".");
const recoil_1 = require("recoil");
/* ==============================
    - 새 알림
    - [사용] 새 알림 여부 값 저장
    - [적용] header
============================== */
exports.alarmState = (0, recoil_1.atom)({
    key: _1.stateKeys['alarm'],
    default: false,
});
exports.alarmListState = (0, recoil_1.atom)({
    key: _1.stateKeys['alarmList'],
    default: false,
});
