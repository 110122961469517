"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styled_components_1 = require("styled-components");
const GlobalStyle = (0, styled_components_1.createGlobalStyle) `
    /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
    */
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    /* ==================== 
        CUSTOM RESET CSS 
    ==================== */
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    html, body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        font: 1rem/1.5 'Noto Sans KR', sans-serif;
        font-weight: 400;
        color: #212121;
        letter-spacing: -0.5px;
    }

    a {
        text-decoration: none;
        color: #212121;
        cursor: pointer;
    }

    button {
        background-color: transparent;
        cursor: pointer;
        border: none;
    }

    input,
    textarea {
        border: 0;
    }

    button,
    input,
    textarea,
    input::placeholder,
    textarea::placeholder {
        font-family: 'Noto Sans KR', sans-serif;
    }

    input:focus,
    textarea:focus,
    input:active,
    textarea:active {
        outline: none;
    }

    strong {
        font-weight: 700;
    }

    ul, ol, li {
        list-style: none;
    }
`;
exports.default = GlobalStyle;
