"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrapProject = exports.scrapPortfolio = void 0;
const index_1 = require("./index");
const urls = {
    client: '/api/user/saveProcClient',
    partner: '/api/user/saveProcPartner',
};
// 포트폴리오 스크랩 하기
function scrapPortfolio(clientNo, portfolioNo) {
    return index_1.client.get(urls.client, {
        params: {
            clientNo: clientNo,
            portfolioNo: portfolioNo,
        },
    });
}
exports.scrapPortfolio = scrapPortfolio;
// 프로젝트 스크랩 하기
function scrapProject(partnerNo, projectNo) {
    return index_1.client.get(urls.partner, {
        params: {
            partnerNo: partnerNo,
            projectNo: projectNo,
        },
    });
}
exports.scrapProject = scrapProject;
