"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToggleInput = exports.UnitSeperateInput = exports.UnitInput = exports.Input = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const style_1 = require("../../ui/Text/style");
const S = __importStar(require("./style"));
function handleEnter(event, callback) {
    if (callback !== null && event.key === 'Enter') {
        event.preventDefault();
        callback(event);
    }
}
/* ==============================
    Input + Unit
============================== */
function Input({ styleTheme = 'box', type = 'text', _style = {}, ...props }) {
    return ((0, jsx_runtime_1.jsxs)(S.Input.Wrapper, { style: _style, children: [(0, jsx_runtime_1.jsx)(S.Input.Box, { _width: props._width, error: props.error && props.error.length > 0, _bg_error: props._bg_error, _type: styleTheme, children: (0, jsx_runtime_1.jsx)(S.Input.Input, { name: props.name, id: props.name, type: type, value: props.value, placeholder: props.placeholder || '', readOnly: props.readonly, onChange: (event) => {
                        props.handleChange(event.target.value);
                    }, _height: props._height }) }), props.guide && !(props.error && props.error.length > 0) && ((0, jsx_runtime_1.jsx)(S.Input.Guide, { _space: props._space, children: props.guide })), props.error && props.error.length > 0 && (0, jsx_runtime_1.jsx)(S.Input.Error, { _space: props._space, children: props.error })] }));
}
exports.Input = Input;
function UnitInput({ type = 'text', styleTheme = 'box', _style = {}, ...props }) {
    return ((0, jsx_runtime_1.jsxs)(S.UnitInput.Wrapper, { style: _style, children: [(0, jsx_runtime_1.jsxs)(S.UnitInput.Box, { _width: props._width, _type: styleTheme, error: props.error && props.error.length > 0, children: [(0, jsx_runtime_1.jsx)(S.UnitInput.Input, { name: props.name, id: props.name, type: type, value: props.value, placeholder: props.placeholder || '', onChange: (event) => {
                            props.handleChange(event.target.value);
                        }, _height: props._height, _type: styleTheme, readOnly: props.readonly }), (0, jsx_runtime_1.jsx)(S.UnitInput.Unit, { _type: styleTheme, children: props.unit })] }), props.guide && !(props.error && props.error.length > 0) && ((0, jsx_runtime_1.jsx)(S.UnitInput.Guide, { _space: props._space, children: props.guide })), props.error && props.error.length > 0 && (0, jsx_runtime_1.jsx)(S.UnitInput.Error, { children: props.error })] }));
}
exports.UnitInput = UnitInput;
function UnitSeperateInput({ type = 'text', styleTheme = 'box', _style = {}, ...props }) {
    return ((0, jsx_runtime_1.jsxs)(S.UnitInput.Wrapper, { style: _style, children: [(0, jsx_runtime_1.jsxs)(S.UnitInput.SeperateWrapper, { children: [(0, jsx_runtime_1.jsx)(S.UnitInput.Box, { _width: props._width, _type: styleTheme, error: props.error && props.error.length > 0, children: (0, jsx_runtime_1.jsx)(S.UnitInput.Input, { name: props.name, id: props.name, type: type, value: props.value, placeholder: props.placeholder || '', onChange: (event) => {
                                props.handleChange(event.target.value);
                            }, _height: props._height, _type: styleTheme, readOnly: props.readonly }) }), (0, jsx_runtime_1.jsx)(S.UnitInput.SeperateUnit, { children: props.unit })] }), props.guide && !(props.error && props.error.length > 0) && ((0, jsx_runtime_1.jsx)(S.UnitInput.Guide, { _space: props._space, children: props.guide })), props.error && props.error.length > 0 && ((0, jsx_runtime_1.jsx)(S.UnitInput.Error, { _space: props._space, children: props.error }))] }));
}
exports.UnitSeperateInput = UnitSeperateInput;
function ToggleInput({ name = '', label = '', value = false, handleChange }) {
    return ((0, jsx_runtime_1.jsxs)(S.ToggleInput.Wrapper, { children: [(0, jsx_runtime_1.jsx)(style_1.Text, { _size: "xs", _weight: "medium", _color: "gray7", children: label }), (0, jsx_runtime_1.jsxs)(S.ToggleInput.Button, { active: value, children: [(0, jsx_runtime_1.jsx)("input", { type: "checkbox", name: name, id: `${name}_tg`, onChange: (event) => handleChange(event.currentTarget.checked), checked: value }), (0, jsx_runtime_1.jsx)("label", { htmlFor: `${name}_tg` })] })] }));
}
exports.ToggleInput = ToggleInput;
