"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STEP_COLORS = exports.DATE_COLORS = exports.ROW_STYLE = exports.CELL_STYLE = void 0;
exports.CELL_STYLE = {
    width: 30,
    height: 40,
    border: 1,
    borderColor: '#EEEEEE',
    fontSize: 12,
};
exports.ROW_STYLE = {
    main: {
        height: 12,
    },
    sub: {
        height: 12,
    },
};
exports.DATE_COLORS = {
    normal: '#FFFFFF',
    today: '#DEF6EB',
    weekend: '#F5F5F5',
};
exports.STEP_COLORS = {
    beforeProgress: '#FFA680',
    onProgress: '#FF791A',
    // onProgressLeftLight: '#e3335b66', //메인 스케쥴 - 진행중이면서 남은 블럭
    onProgressLeft: '#e3335b4d',
    finished: '#E3335B',
    finishedDark: '#FF4000', // 서브 스케쥴 - 진행 완료 블럭
};
