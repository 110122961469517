"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serviceWorker = void 0;
exports.serviceWorker = {
    run() {
        this.cache();
        this.register();
    },
    register() {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker
                    .register('/service-worker.js')
                    .then((registration) => {
                    console.log('SW registered: ', registration);
                })
                    .catch((registrationError) => {
                    console.error('SW registration failed: ', registrationError);
                });
            });
        }
    },
    cache() {
        const cacheName = 'cache-v1';
        const precacheResources = ['/offline.html', '/favicon.ico', './manifest.json', './icon168.png'];
        self.addEventListener('install', (event) => {
            console.log('Service worker install event!');
            event.waitUntil(caches.open(cacheName).then((cache) => cache.addAll(precacheResources)));
        });
        self.addEventListener('activate', (event) => {
            console.log('Service worker activate event!');
        });
        self.addEventListener('fetch', (event) => {
            console.log('Fetch intercepted for:', event.request.url);
            event.respondWith(caches.match(event.request).then((cachedResponse) => {
                if (cachedResponse) {
                    return cachedResponse;
                }
                return fetch(event.request);
            }));
        });
    },
};
