"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadFile = exports.isValidFileExtByUri = exports.isValidFileExt = exports.isValidFileSize = exports.getfileFromUrl = exports.dataUrlToMultiPartFile = exports.FileFormDataUri = exports.dataUriFromFile = exports.filesFromUrls = exports.fileFromUrl = exports.jsonBlobData = void 0;
/* ==============================
    multipart files - json blob
============================== */
function jsonBlobData(data) {
    return new Blob([JSON.stringify(data)], { type: 'application/json' });
}
exports.jsonBlobData = jsonBlobData;
/* ==============================
    string url => File
============================== */
async function fileFromUrl(fileUrl, fileName) {
    const response = await fetch(fileUrl, {
        mode: 'cors',
        method: 'GET',
        headers: {
            origin: 'http://localhost:3000',
            'Access-Control-Allow-Origin': '*',
        },
    });
    const dataUrl = await response.blob();
    return new File([dataUrl], fileName, { type: dataUrl.type });
}
exports.fileFromUrl = fileFromUrl;
/* ==============================
    string urls => Files
============================== */
async function filesFromUrls(serverFiles) {
    return await Promise.all(serverFiles.map((file) => fileFromUrl(file.url, file.name)));
}
exports.filesFromUrls = filesFromUrls;
/* ==============================
    File => dataURI
============================== */
const dataUriFromFile = (file) => new Promise((res, rej) => {
    const fr = new FileReader();
    fr.readAsDataURL(file);
    fr.onload = () => res(fr.result);
    fr.onerror = () => rej('');
});
exports.dataUriFromFile = dataUriFromFile;
/* ==============================
    dataURI => File
============================== */
function FileFormDataUri(dataUri, fileName = '') {
    const arr = dataUri.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--)
        u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], fileName, { type: mime });
}
exports.FileFormDataUri = FileFormDataUri;
/* ==============================
    dataURI => MultPartFile
============================== */
function dataUrlToMultiPartFile(dataUri) {
    const arr = dataUri.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--)
        u8arr[n] = bstr.charCodeAt(n);
    return new File([u8arr], 'test123.jpg', { type: 'multipart/form-data' });
}
exports.dataUrlToMultiPartFile = dataUrlToMultiPartFile;
async function getfileFromUrl(url, name) {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name);
}
exports.getfileFromUrl = getfileFromUrl;
/* ==============================
    valid file size : mb 기준
============================== */
function isValidFileSize(file, size) {
    const maxSize = size * 1024 ** 2;
    if (Array.isArray(file)) {
        const totalSize = file.reduce((acc, item) => {
            return acc + item.size;
        }, 0);
        return totalSize <= maxSize;
    }
    else {
        return file.size <= maxSize;
    }
}
exports.isValidFileSize = isValidFileSize;
function isValidFileExt(file, exts) {
    const nameArr = file.name.split('.').pop().toLowerCase();
    const extArr = exts.map((item) => item.replace('.', '').toLowerCase());
    return extArr.indexOf(nameArr) >= 0;
}
exports.isValidFileExt = isValidFileExt;
function isValidFileExtByUri(uri, exts) {
    if (!uri.includes('.') || uri.length <= 1) {
        return false;
    }
    const uriStringArr = uri.split('.');
    const ext = uriStringArr.pop();
    return exts.indexOf(ext) >= 0;
}
exports.isValidFileExtByUri = isValidFileExtByUri;
async function downloadFile(url, name) {
    const file = await getfileFromUrl(url, name);
    const fr = new FileReader();
    fr.addEventListener('load', function () {
        const a = document.createElement('a');
        const result = fr.result;
        a.download = name;
        a.href = result;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    });
    fr.readAsDataURL(file);
}
exports.downloadFile = downloadFile;
