"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const D = __importStar(require("../Display"));
const style_1 = require("../Box/style");
const style_2 = require("../Button/style");
const style_3 = require("../Text/style");
function NoContetns({ title, descriptions, buttonPath, buttonText, img, btnTheme = 'dark', styleTheme = 'border', _height, }) {
    const navigate = (0, react_router_dom_1.useNavigate)();
    return ((0, jsx_runtime_1.jsx)(style_1.Box, { _border: styleTheme === 'border' ? '2px' : '0', _bdcolor: "gray2", _radius: "10px", _width: "100%", _height: _height ? _height : img ? 'auto' : '204px', _padding: "60px 16px", children: (0, jsx_runtime_1.jsx)(D.FlexRows, { _height: "100%", _content: "center", _items: "center", children: (0, jsx_runtime_1.jsxs)(D.FlexCols, { _content: "center", _items: "center", _gap: 24, children: [img && img, title && ((0, jsx_runtime_1.jsx)(style_3.Text, { _size: "l", _weight: "medium", fAlign: "center", children: title })), descriptions && ((0, jsx_runtime_1.jsx)(style_3.Text, { _size: "xs", _color: "gray6", fAlign: "center", children: descriptions })), buttonText && ((0, jsx_runtime_1.jsx)(style_2.CapsuleButton, { as: "button", onClick: () => {
                            navigate(buttonPath);
                        }, _width: 150, _height: 40, _border: 1, _bgcolor: btnTheme === 'dark' ? 'gray9' : 'white', _bdcolor: btnTheme === 'dark' ? 'gray9' : 'primaryMain', _color: btnTheme === 'dark' ? 'white' : 'primaryMain', _size: "s", children: buttonText }))] }) }) }));
}
exports.default = NoContetns;
