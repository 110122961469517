"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPortfolioPostData = void 0;
const text_1 = require("../utils/text");
function getPortfolioPostData(data) {
    if (!data)
        return null;
    const { authCheck, portfolioNo, portfolioTitle, partnerProfile, projectClass, serviceSector, wantTechnologyCtg, wantTechnologyPlan, wantTechnologyDesign, wantTechnologyDev, wantDevelop, wantFramework, participation, working, portfolioContent, portfolioFiles, portfolioTagArr, zzimNum, partnerId, userNo, siDo, siDoArea, guGun, guGunArea, authcheck, corporateCtg, completePortfolio, contractPortfolio, } = data;
    return {
        authCheck: authCheck === 3 || authCheck === 5,
        portfolioNo: portfolioNo || -1,
        portfolioTitle: portfolioTitle || '',
        partnerProfile: partnerProfile ? partnerProfile.s3Link + partnerProfile.s3FileName : '',
        projectClass: (0, text_1.dashString)(projectClass),
        serviceSector: (0, text_1.dashString)(serviceSector),
        wantTechnologyCtg: wantTechnologyCtg.replaceAll(',', ', '),
        wantTechnologyDev: wantTechnologyDev.replaceAll(',', ', '),
        wantTechnologyDesign: wantTechnologyDesign.replaceAll(',', ', '),
        wantTechnologyPlan: wantTechnologyPlan.replaceAll(',', ', '),
        wantDevelop: (0, text_1.dashString)(wantDevelop),
        wantFramework: (0, text_1.dashString)(wantFramework),
        participation: `${(0, text_1.dashString)(participation)}%`,
        working: `${(0, text_1.dashString)(working)}달`,
        portfolioContent: (0, text_1.dashString)(portfolioContent),
        portfolioFiles: portfolioFiles || [],
        portfolioTagArr: portfolioTagArr,
        zzimNum: zzimNum || 0,
        partnerId: (0, text_1.dashString)(partnerId),
        partnerNo: userNo || -1,
        siDo: (0, text_1.dashString)(siDo),
        siDoArea: (0, text_1.dashString)(siDoArea),
        guGun: (0, text_1.dashString)(guGun),
        guGunArea: (0, text_1.dashString)(guGunArea),
        authcheck: authcheck || false,
        corporateCtg: (0, text_1.dashString)(corporateCtg),
        completePortfolio: completePortfolio || 0,
        contractPortfolio: contractPortfolio || 0,
    };
}
exports.getPortfolioPostData = getPortfolioPostData;
