"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMobileTitle = exports.mobiletTitleState = void 0;
const react_1 = require("react");
const _1 = require(".");
const recoil_1 = require("recoil");
const react_router_dom_1 = require("react-router-dom");
/* ==============================
    MOBILE-TITLE
    - [사용] 페이지마다 모바일 타이틀 개별 설정
============================== */
exports.mobiletTitleState = (0, recoil_1.atom)({
    key: _1.stateKeys['mobileTitle'],
    default: '',
});
function useMobileTitle() {
    const location = (0, react_router_dom_1.useLocation)();
    const resetMobileTitle = (0, recoil_1.useResetRecoilState)(exports.mobiletTitleState);
    const setMobileTitle = (0, recoil_1.useSetRecoilState)(exports.mobiletTitleState);
    function setTitle(value) {
        if (typeof value === 'string' && value.length > 0) {
            setMobileTitle(value);
        }
    }
    (0, react_1.useEffect)(() => {
        return () => {
            resetMobileTitle();
        };
    }, [location]);
    return {
        setTitle: setTitle,
    };
}
exports.useMobileTitle = useMobileTitle;
