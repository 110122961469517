"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testProjecFormData = exports.testNewProjectState = exports.projectFormData = exports.projectFormState = void 0;
const recoil_1 = require("recoil");
const _1 = require(".");
/* ==============================
    - NEW PROJECT FORM
    - [사용] 프로젝트 등록 폼 로컬 스토리지에 저장
    - [적용] NewProjectContainer
============================== */
// storage effect 적용 고려
exports.projectFormState = (0, recoil_1.atom)({
    key: _1.stateKeys['projectForm'],
    default: localStorage.getItem(_1.stateKeys['projectForm']),
});
exports.projectFormData = (0, recoil_1.selectorFamily)({
    key: `SAVED_${_1.stateKeys['projectForm']}`,
    get: (userNo) => ({ get }) => {
        const saved = get(exports.projectFormState);
        if (!saved)
            return saved;
        const data = JSON.parse(saved);
        if (userNo === data.userNo) {
            return data;
        }
        else {
            localStorage.removeItem(_1.stateKeys['projectForm']);
            return null;
        }
    },
    set: (userNo) => ({ set }, newData) => {
        if (newData === null) {
            localStorage.removeItem(_1.stateKeys['projectForm']);
            set(exports.projectFormState, null);
        }
        else {
            const stringData = JSON.stringify(newData);
            localStorage.setItem(_1.stateKeys['projectForm'], stringData);
            set(exports.projectFormState, stringData);
        }
    },
});
/* ==============================
    - TEST NEW PROJECT
============================== */
const testKey = 'test' + _1.stateKeys['projectForm'];
const projectFormLocalStorageEffect = () => ({ setSelf, onSet }) => {
    const savedData = localStorage.getItem(testKey);
    setSelf(savedData ? JSON.parse(savedData) : null);
    onSet((newValue) => {
        if (newValue === null) {
            localStorage.removeItem(testKey);
        }
        else {
            localStorage.setItem(testKey, JSON.stringify(newValue));
        }
    });
};
exports.testNewProjectState = (0, recoil_1.atom)({
    key: testKey,
    default: null,
    effects: [projectFormLocalStorageEffect()],
});
exports.testProjecFormData = (0, recoil_1.selectorFamily)({
    key: testKey + '_DATA',
    get: (userNo) => ({ get }) => {
        const saved = get(exports.testNewProjectState);
        if (saved && saved['userNo'] && saved['userNo'] === userNo) {
            return saved;
        }
        else {
            return null;
        }
    },
    set: (userNo) => ({ set }, newValue) => {
        set(exports.testNewProjectState, newValue);
    },
});
