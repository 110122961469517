"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const fontSizes = {
    xl: '1.25rem',
    l: '1.125rem',
    base: '1rem',
    s: '0.875rem',
    xs: '0.8125rem',
    xxs: '0.75rem',
};
const fontWeights = {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
};
const colors = {
    black: '#000000',
    white: '#ffffff',
    error: '#BA1A1A',
    primary9: '#c23420',
    primary8: '#ea472c',
    primary7: '#f94d31',
    primaryMain: '#DC4128',
    primary5: '#ff5336',
    primary4: '#ff6e51',
    primary3: '#ff8970',
    primary2: '#ffab98',
    primary1: '#ffccc0',
    primary0: '#fbe9e8',
    secondary9: '#006022',
    secondary8: '#008038',
    secondary7: '#009244',
    secondary6: '#00A451',
    secondary5: '#00B35B',
    secondary4: '#00C072',
    secondary3: '#00CC8B',
    secondary2: '#76DAAD',
    secondary1: '#B0E9CD',
    secondary0: '#DEF6EB',
    gray9: '#212121',
    gray8: '#424242',
    gray7: '#616161',
    gray6: '#757575',
    gray5: '#9E9E9E',
    gray4: '#BDBDBD',
    gray3: '#E0E0E0',
    gray2: '#EEEEEE',
    gray1: '#F5F5F5',
    gray0: '#FAFAFA',
    blue: '#1E88E5',
    red: '#E53935',
};
const devices = {
    desktop: '1440px',
    laptop: '1024px',
    tablet: '902px',
    tabletSmall: '768px',
    mobile: '425px',
};
const media = {
    desktop: `max-width:${devices.desktop}`,
    laptop: `max-width:${devices.laptop}`,
    tablet: `max-width:${devices.tablet}`,
    tabletSmall: `max-width:${devices.tabletSmall}`,
    mobile: `max-width:${devices.mobile}`,
};
const GlobalTheme = {
    fontSizes,
    fontWeights,
    colors,
    media,
};
exports.default = GlobalTheme;
